
import { Options, Vue } from "vue-class-component";
import ImageViewer from "@/components/ImageViewer.vue";

@Options({
  components: {
    ImageViewer,
  },
})
export default class Home extends Vue {}
