class AppScreen {
  public isMobile() {
    return /android.+mobile|ip(hone|[oa]d)/i.test(navigator.userAgent);
  }

  public isPortrait() {
    return window.innerHeight > window.innerWidth;
  }
}

export default new AppScreen();
