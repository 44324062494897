import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3e2427a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      ref: "image",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)), ["prevent"])),
      class: "rs",
      src: _ctx.currentPhoto
    }, null, 8, _hoisted_2)
  ]))
}