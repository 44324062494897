
import { Vue } from "vue-class-component";
import AppEventBus, { AppEvent } from "./Events";
import AppScreen from "./Screen";

export default class MainApp extends Vue {
  declare $refs: {
    toggleButton: HTMLButtonElement;
    footer: HTMLDivElement;
  };

  private hideMenuText = true;

  onAspectRatio() {
    console.log("Show Aspect Ratio");
    AppEventBus.emitter.emit(AppEvent.SHOW_ASPECT_RATIO);
  }

  onShowBnW() {
    console.log("Show BnW");
    AppEventBus.emitter.emit(AppEvent.SHOW_BnW);
  }

  onShowColors() {
    console.log("Show Colors");
  }

  onGotoInstagram() {
    console.log("Goto Instagram");
  }

  onSendMail() {
    console.log("Send Mail");
  }

  onShowGrid() {
    console.log("Show Grid");
  }

  showHideMenuText() {
    this.hideMenuText = !this.$refs.toggleButton.offsetParent;
  }

  onToggleClicked() {
    this.showHideMenuText();
  }

  onResize() {
    this.showHideMenuText();
    console.log("Is mobile", AppScreen.isMobile());
  }

  mounted() {
    window.addEventListener("resize", this.onResize.bind(this));
  }
}
