import mitt from "mitt";

export enum AppEvent {
  GOTO_INSTAGRAM = "GOTO_INSTAGRAM",
  SHOW_ASPECT_RATIO = "SHOW_ASPECT_RATIO",
  SEND_MAIL = "SEND_MAIL",
  SHOW_BnW = "SHOW_BnW",
  SHOW_COLORS = "SHOW_COLORS",
  SHOW_GRID = "SHOW_GRID",
}

class AppEventBus {
  private _emitter: any;

  constructor() {
    this._emitter = mitt();
  }

  get emitter() {
    return this._emitter;
  }
}

export default new AppEventBus();
