
import { Vue } from "vue-class-component";
import AppEventBus, { AppEvent } from "../Events";
import AppScreen from "../Screen";
import Timer from "../Timer";

const DELAY = 10;

export default class ImageViewer extends Vue {
  declare $refs: {
    container: HTMLDivElement;
    image: HTMLImageElement;
  };

  private modal: any;
  private metadata: any = {};
  private index = -1;
  private currentPhoto = "";
  private photos: any[] = [];
  private interval: any = null;
  private timer: Timer | null = null;

  async beforeCreate() {
    try {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      const response = await fetch(new Request("/data/photos.json"), {
        method: "GET",
        headers: headers,
        cache: "default",
      });
      this.metadata = await response.json();
      const is3x4 = AppScreen.isPortrait();
      this.photos = this.metadata.photos.filter((item: any) =>
        item.keywords.includes("portrait")
      );
      this.selectPhoto();
      console.log(this.photos);
    } catch (error) {
      console.log(error);
    }
  }

  get currentWithAspectRatio() {
    return this.photos && this.photos.length > 0
      ? `/images/${this.photos[this.index].url}`
      : "";
  }

  onNext() {
    this.timer?.stop();
    this.timer?.start();
    this.selectPhoto();
  }

  selectPhoto() {
    if (this.photos.length > 0) {
      console.log("selectConsole");
      this.$refs.image.classList.remove("fade-in");
      this.$refs.image.classList.add("fade-out");
      this.index = (this.index + 1) % this.photos.length;
      const baseUrl = `/images/${AppScreen.isPortrait() ? "3x4/" : ""}`;
      this.currentPhoto = `${baseUrl}${this.photos[this.index].url}`;
    }
  }

  mounted() {
    this.timer = new Timer(DELAY, this.selectPhoto.bind(this));
    this.timer?.start();

    this.$refs.image.addEventListener("load", () => {
      console.log("Loaded");
      this.$refs.image.classList.remove("fade-out");
      this.$refs.image.classList.add("fade-in");
    });
  }
}
