export default class Timer {
  private last = -1;
  private handle = -1;
  private delay = 0;
  private callback: any;

  constructor(delay: number, callback: any) {
    this.delay = delay;
    this.callback = callback;
  }

  start() {
    this.last = new Date().getTime() / 1000;
    this.tick();
  }

  stop() {
    cancelAnimationFrame(this.handle);
  }

  tick() {
    const now = new Date().getTime() / 1000;

    if (now - this.last >= this.delay) {
      this.last = now;
      if (this.callback) {
        this.callback();
      }
    }

    this.handle = requestAnimationFrame(this.tick.bind(this));
  }
}
